const resizeContainerHeight = (e) => {
  let headerHeight = 0
  if (document.body.querySelector('header')) {
    headerHeight = document.body.querySelector('header').offsetHeight
  }
  document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
  document.documentElement.style.setProperty('--app-body-height', `${window.innerHeight - headerHeight}px`)
}

document.addEventListener('turbolinks:load', () => {
  resizeContainerHeight()
  window.addEventListener('resize', resizeContainerHeight)
})
document.addEventListener("turbolinks:before-cache", () => {
  window.removeEventListener('resize', resizeContainerHeight)
})
